import { useToast } from 'components/app/NotificationArea'
import Button from 'components/base/Button'
import { windowDimensionsAtom } from 'components/sidebar/lib/atoms'
import { motion } from 'motion/react'
import { useAtom } from 'jotai'
import { FC, c } from 'lib/component-utils'
import { useUserContext } from 'lib/context'
import { PersonContactRequest } from 'lib/dto'
import { useIntl } from 'lib/intl-utils'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

const ElevenEleven: FC = () => {
  const ctx = useUserContext()
  const { t } = useIntl()
  const [expanded, setExpanded] = useState(false)
  const showToast = useToast()
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<PersonContactRequest>({ mode: 'onSubmit' })
  const [[width, height], updateWindowDimensions] = useAtom(windowDimensionsAtom)

  useEffect(() => {
    const resizeHandler = debounce(() => {
      updateWindowDimensions([window.innerWidth, window.innerHeight])
      if (window.innerWidth > 640) {
        setExpanded(true)
      }
    }, 100)

    resizeHandler()
    window.addEventListener('resize', resizeHandler)

    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  const onContactFormSubmit = useCallback(async (data: PersonContactRequest) => {
    const response = await ctx.requestElevenEleven(data)

    if (response.status === 'success') {
      showToast('success', t`landing.we_will_contact_you`)
      setExpanded(false)
      reset()
      return
    }
    showToast('error', t`landing.something_went_wrong`)
  }, [])

  useEffect(() => {
    const onScroll = () => {
      setExpanded(width > 640 && window.scrollY === 0)
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <motion.div
      onClick={() => setExpanded(true)}
      className={c`fixed z-50 transition-all overflow-hidden duration-500 bottom-16 inset-x-4 sm:left-auto sm:right-10 ring-1 ring-white/10 bg-gray-950 text-white px-6 py-4 rounded-xl shadow-2xl ${!expanded} cursor-pointer`}
      initial={{ height: width > 640 ? '458px' : '500px', width: width > 640 ? '505px' : '320px' }}
      animate={{
        height: expanded ? (width > 640 ? '458px' : '500px') : '105px',
        width: expanded ? (width > 640 ? '505px' : 'calc(100vw - 2rem)') : '320px',
      }}
      transition={{ duration: 0.1 }}
    >
      <p className="text-sm text-gray-300">Чёрная пятница уже на Sellmonitor</p>
      <h2 className="text-xl font-semibold text-lime-300">Удваиваем подписку</h2>
      <p className="text-base">
        Купи год — получи второй в подарок
        {expanded && (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1, delay: 0.3 }}
          >
            . А за подписку на два маркетплейса или маркетплейс с сопровождением подарим банку
            черной икры.
          </motion.span>
        )}
      </p>
      {expanded && (
        <motion.form
          onSubmit={handleSubmit(onContactFormSubmit)}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1, delay: 0.3 }}
          className="grid gap-4 grid-cols-1 mt-4 relative"
        >
          <img
            src="/next/img/landing/caviar.png"
            alt="caviar"
            className="absolute hidden sm:block top-0 -right-16 pointer-events-none"
          />
          <input
            {...register('name', { required: true })}
            type="text"
            placeholder="Имя"
            className="w-full bg-transparent border-0 border-b border-white/20 focus:border-lime-300 placeholder:text-white/50 focus:ring-0 focus:outline-none"
            autoComplete="name"
          />
          <input
            {...register('email', { required: true })}
            type="email"
            placeholder="Email"
            className={c`bg-transparent border-0 border-b border-white/20 focus:border-lime-300 placeholder:text-white/50 focus:ring-0 focus:outline-none ${
              width > 640 ? 'w-[15rem]' : 'w-full'
            }`}
            autoComplete="email"
          />
          <input
            {...register('phone', { required: true })}
            type="tel"
            placeholder="Телефон"
            className={c`bg-transparent border-0 border-b border-white/20 focus:border-lime-300 placeholder:text-white/50 focus:ring-0 focus:outline-none ${
              width > 640 ? 'w-[15rem]' : 'w-full'
            }`}
            autoComplete="tel"
          />
          <Button type="submit" theme="accent" className="mt-2 !justify-center">
            Оставить заявку
          </Button>
        </motion.form>
      )}
      {expanded && (
        <motion.p
          className="text-xs text-gray-300 mt-4 max-w-prose"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1, delay: 0.3 }}
        >
          Чтобы воспользоваться предложением, оставьте заявку — мы быстро свяжемся с вами. Или пока
          почитайте про сервис — предложение будет ждать вас здесь.{' '}
          <a
            className="text-lime-300/70"
            href="/doc/sellmonitor/ru/black_friday.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Полные условия акции.
          </a>
        </motion.p>
      )}
    </motion.div>
  )
}

export default ElevenEleven
